import { AuthJsError } from "./AuthJsError";
import { events } from "./events";
import { Logger } from "./log";
import { OverlayIframe } from "./overlayIframe";

const log = new Logger({ logger: "authUi"});

class AuthUi {
  signInUri: string;

  /**
   *
   * @param {Object} options - Options
   * @param {string} options.signInUri - signInUri
   * @throws {AuthJsError} - on invalid parameters
   */
  constructor({ signInUri }: { signInUri: string | undefined; }) {

    if (!signInUri) {

      throw new AuthJsError("Missing signInUri parameter!");
    }

    this.signInUri = signInUri;
  }

  #getSignInUri(options = { target: "" }) {

    if (options.target) {
      return `${this.signInUri}?target=${encodeURIComponent(options.target)}`;
    }
    return this.signInUri;
  }

  openSignInOverlay({ noCloseButton = false, onOverlaySuccess }: { noCloseButton: boolean, onOverlaySuccess: () => Promise<void> }) {

    log.debug("openSignInOverlay triggered", { noCloseButton, onOverlaySuccess });
    if (document.querySelector(OverlayIframe.TagName)) {

      log.warn("Overlay already open");
    }

    // This is a temporary solution. When auth.js and auth.esm.js are both present, the overlayIframe might already be defined.
    // We get the constructor of the potentially already defined overlayIframe and use that one to instantiate a new
    // overlayIframe. If it does not exist, we use the "internal" OverlayIframe constructor
    const existingOverlayIframe = window.customElements.get(OverlayIframe.TagName);
    let overlay;
    const overlayIframeOptions = {
      srcUri: this.signInUri,
      noCloseButton,
      onOverlaySuccess,
      closeMessageEventName: events.dst.user.loggedIn
    };
    if (existingOverlayIframe) {

      overlay = new existingOverlayIframe(overlayIframeOptions);

    } else {

      overlay = new OverlayIframe(overlayIframeOptions);
    }

    document.body.appendChild(overlay);
  }

  openSignInPageload({ target = document.location.href }) {

    log.debug("openSignInPageload triggered", { target });

    const signInUri = this.#getSignInUri({ target });
    log.debug(`Redirecting to '${signInUri}'...`);
    document.location.assign(signInUri);
  }
}

export {
  AuthUi
};
