import { AuthJsError } from "./AuthJsError";
import { Logger } from "./log";

const log = new Logger({ logger: "overlayIframe"});
const template = document.createElement("template");

// TODO: CSS f. Overlay!
template.innerHTML =
`
<style>
  .overlay-backdrop {
    background: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: all 0.25s;
    width: 100%;
    z-index: 900;
  }

  .overlay-backdrop.overlay-hidden {
    display: none;
  }

  .overlay-backdrop * {
    box-sizing: border-box;
  }

  @supports (backdrop-filter: blur(1px)) or (-webkit-backdrop-filter: blur(1px)) {
    .overlay-backdrop {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .overlay {
    box-shadow: 0 16px 24px 2px rgb(0, 0, 0, 0.14), 0 6px 30px 5px rgb(0, 0, 0, 0.12), 0 8px 10px -5px rgb(0, 0, 0, 0.4);
    background-color: var(--theme--surface-2dp, #fff);
    border: 0.0625rem solid var(--theme--surface-2dp-border, hsl(0deg 0% 88%));
    border-radius: 0.375rem;
    box-sizing: border-box;
    color: var(--theme--on-surface, hsl(0deg 0% 13%));
    height: calc(100vh - 30px);
    margin: 15px auto;
    max-width: calc(100vw - 30px);
    overflow: hidden;
    position: relative;
    width: 490px;
  }

  @media (min-width: 768px) {
    .overlay {
      height: 640px;
      margin: 85px auto;
      max-height: 90vh;
    }
  }

  @media (min-width: 960px) {
    .overlay {
        max-width: 600px;
    }
  }

  iframe {
    border: 0;
    height: 100%;
    width: 100%;
  }

  button {
    background-color: hsl(var(--theme--surface-2dp--hsl)/var(--opacity-70));
    border: 0;
    border-radius: 100%;
    box-sizing: border-box;
    color: hsl(var(--theme--on-base--hsl, 0deg 0% 13%)/var(--contrast-opacity-100, var(--opacity-60), 0.6));
    cursor: pointer;
    display: flex;
    padding: 0.5rem;
    position: absolute;
    right: 0.4375rem;
    top: 0.4375rem;
    transition: background-color .2s,color .2s;
    z-index: 1;
  }

  button:hover, button:focus, button:active {
    color: currentColor;
  }

  button:hover {
    background-image: linear-gradient(to right, hsl(var(--theme--on-base--hsl, 0deg 0% 13%)/var(--opacity-5, 0.05)), hsl(var(--theme--on-base--hsl, 0deg 0% 13%)/var(--opacity-5, 0.05)));
  }

  button:focus {
    outline: 0;
  }

  @media (hover: hover) and (pointer: fine) {
    button:focus-visible {
      outline: 0.1875rem solid var(--theme--on-base, hsl(0deg 0% 13%));
      outline-offset: 0.125rem;
      text-decoration: none;
    }
  }

  button svg {
    height: 1.5rem;
    width: 1.5rem;
  }
  </style>

  <div class="overlay-backdrop">
    <div class="overlay">
      <button hidden type="button" title="Dialog schließen">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path>
        </svg>
      </button>
      <iframe></iframe>
    </div>
  </div>
`;

type Args = {
  srcUri: string;
  onOverlaySuccess: () => Promise<void>;
  noCloseButton: boolean;
  closeMessageEventName: string
};

class OverlayIframe extends HTMLElement {

  #shadow;
  #iframe;
  #button;
  #onMessageListener;

  static TagName = "authjs-overlay-iframe";
  srcUri: string;
  onSuccess: () => Promise<void>;
  closeMessageEventName: string;

  constructor({ srcUri, onOverlaySuccess, noCloseButton = false, closeMessageEventName }: Args) {

    log.debug("constructing");
    super();

    if (!srcUri) {

      throw new AuthJsError("Missing srcUri parameter!");
    }
    this.srcUri = srcUri;
    this.onSuccess = onOverlaySuccess;
    this.closeMessageEventName = closeMessageEventName;

    // modify the accessiblity tree (simulate dialog.open similar to piano experiences)
    this.setAttribute("tabindex", "0");
    this.setAttribute("role", "dialog");
    this.setAttribute("aria-modal", "true");
    this.setAttribute("aria-label", "Anmelden oder registrieren");
    this.setAttribute("aria-description", "Melden Sie sich mit Ihrem bestehenden Konto an oder registrieren Sie sich mit einem neuen Konto.");
    setTimeout(()=>{
      this.focus({ preventScroll: true });
    }, 1000);

    // prevent scrolling on body when modal is open
    document.body.classList.add("is-modal");

    this.#shadow = this.attachShadow({mode: "open"});
    this.#shadow.appendChild(template.content.cloneNode(true));
    this.#button = this.#shadow.querySelector("button");
    this.#iframe = this.#shadow.querySelector("iframe");

    document.addEventListener('keyup', (event)=>{
      // close overlay on ESC-key
      if (event.key === "Escape") {
        this.#close(false);
      }

      // focus trap
      if (event.key === "Tab") {
        if (document.activeElement !== this) {
          event.preventDefault();
          if (event.shiftKey) {
            this.#iframe?.focus();
          } else {
            if (noCloseButton) {
              this.focus();
            } else {
              this.#button?.focus()
            }
          }
        }
      }
    });

    if (noCloseButton) {

      this.#button?.remove();
    } else {

      this.#button?.addEventListener("click", () => this.#close(false));
    }

    this.#iframe!.src = this.srcUri;
    this.#iframe!.onload = () => this.#onIframeLoad();

    if (!this.closeMessageEventName) {

      log.warn("No closeMessageEventName provided");
    } else {

      this.#onMessageListener = this.#onMessage.bind(this);
      window.addEventListener("message", this.#onMessageListener as EventListener);
    }
  }

  #onMessage(event: MessageEvent) {

    log.debug("Checking message event origin", event);
    if (!this.srcUri.startsWith(event.origin)) {

      log.debug(`message event origin differs from '${this.srcUri}'. Ignoring.`);
      return;
    }

    if (event.data.eventName === this.closeMessageEventName) {

      this.#close(true);
    }
  }

  #onIframeLoad() {

    log.debug("iframe loaded!");
    this.#button!.hidden = false;
  }

  #close(success: boolean) {

    log.debug(`Closing overlay. success: ${success}`);
    document.body.classList.remove("is-modal");
    this.remove();
    if (success && typeof this.onSuccess === "function") {

      log.debug("Executing onClose callback");
      this.onSuccess();
    }
  }

  connectedCallback() {

    log.debug("connected!");
  }

  disconnectedCallback() {

    log.debug("disconnected! removing listeners");
    window.removeEventListener("message", this.#onMessageListener as EventListener);
  }

  attributeChangedCallback(name: string, oldVal: string, newVal: string) {

    log.debug(`Attribute: ${name} changed!`, oldVal, newVal);
  }

  adoptedCallback() {

    log.debug("adopted!");
  }
}

window.customElements.get(OverlayIframe.TagName) || window.customElements.define(OverlayIframe.TagName, OverlayIframe);

export {
  OverlayIframe
};
